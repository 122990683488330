<script setup lang="ts">
import Alert from '@/components/core/notification/Alert.vue'

useHead({
  title: 'Maplerad Dashboard',
  link: [{ rel: 'icon', type: 'image', href: '/favicon.png' }],
})

const props = useAttrs()
const router = useRouter()
const route = useRoute()
const { locale, locales, setLocale } = useI18n()

const fullView = (props['full-view'] as string) || false

const handleCommand = (item: string) => {
  router.push({
    path: route.path,
    query: { ...route.query, locale: item },
  })
}

onMounted(() => {
  if (!route.query.locale) {
    // Todo: ensure that local is set on log in
    router.push({
      path: route.path,
      query: { ...route.query, locale: 'en' },
    })
  }
})

watch(
  () => route.query.locale,
  (newValue) => {
    setLocale(newValue as string)
  },
)
</script>
<template>
  <main>
    <Alert />
    <div class="h-screen">
      <div class="flex">
        <section
          class="h-screen overflow-x-hidden flex w-full flex-col bg-white"
          :class="{ 'lg:w-1/2': !fullView }"
        >
          <div class="py-12 pl-12 relative"></div>

          <div class="px-4 sm:px-0 max-w-[460px] w-full mx-auto flex flex-col gap-8 flex-1">
            <slot />
          </div>

          <div class="self-center flex justify-between items-center gap-8 py-8">
            <Typography
              variant="smMedium"
              color="text-gray500"
              class="flex items-center gap-2"
            >
              {{ $t('momoCollection.poweredBy') }}
              <Icon
                name="logo-new"
                class="h-20 text-gray500"
              />
            </Typography>
            <Divider
              direction="vertical"
              class="!h-8 !m-0"
            />
            <Typography
              class="hover:underline"
              color="text-gray500"
              as="NuxtLink"
              target="_blank"
              to="https://wirepay.notion.site/Maplerad-Terms-of-Use-Privacy-Policy-346259d44725482c829b10f516400f14"
            >
              {{ $t('auth.legalPrivacy') }}
            </Typography>
            <Typography
              class="hover:underline"
              color="text-gray500"
              as="NuxtLink"
              target="_blank"
              to="https://intercom.help/maplerad/en"
            >
              {{ $t('auth.helpCenter') }}
            </Typography>
            <Dropdown
              popper-class="-mt-1"
              trigger="click"
              @command="handleCommand"
            >
              <button class="flex gap-1.5 items-center">
                <Typography
                  color="text-gray500"
                  class="uppercase"
                >
                  {{ locale }}
                </Typography>
              </button>
              <template #dropdown>
                <DropdownItem
                  v-for="item in locales"
                  :key="item.code"
                  :command="item.code"
                >
                  {{ item.name }}
                </DropdownItem>
              </template>
            </Dropdown>
          </div>
        </section>

        <div
          class="h-screen flex-col justify-end hidden bg-gray200"
          :class="{ 'lg:flex lg:w-1/2': !fullView }"
        >
          <div class="py-12 pl-12 relative"></div>

          <div class="px-4 sm:px-0 max-w-[460px] w-full mx-auto flex-1">
            <slot name="more-details" />
          </div>

          <Typography
            color="text-gray500"
            variant="sm"
            class="text-center pb-8 justify-self-end"
            >{{ $t('auth.copyright') }}
          </Typography>
        </div>
      </div>
    </div>
  </main>
</template>
